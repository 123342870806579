import React from "react"
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import Parallax from '../parallax'
import { Navigation, Pagination, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const FormattedDate = ({ item }) => {
  const { date } = item
  const [weekday, day, month] = date.split(' ') || [null, null, null]
  return (
    <span className="fullDate">
      <span className="weekday">{weekday}</span>{' '}
      <span className="day">{day}</span>{' '}
      <span className="month">{month}</span>{' '}
      {!!item.time && <span className="time">kl {item.time}</span>}
    </span>
  )
}

const Component = ({ item, num, pageContext }) => {
  const currentDate = new Date();

  // Filter slides based on shownUntil date
  const filteredSlides = item?.slides?.filter(slide => {
    const showUntil = slide?.shownUntil ? new Date(slide.shownUntil) : null
    return !showUntil || showUntil >= currentDate
  }) || [];

  return (
    <section className={`topSlider`}>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        preloadImages={false}
        pagination={{ clickable: true, dynamicBullets: true }}
      >
        {filteredSlides.map((item, i, arr) => {
          const imageMobile = !!item?.imageMobile?.localFile
            ? getImage(item?.imageMobile?.localFile)
            : false
          const imageLocal = !!item?.image?.localFile
            ? getImage(item?.image?.localFile)
            : false

          const images =
            !!imageMobile && !!imageLocal
              ? withArtDirection(imageLocal, [
                {
                  media: '(max-width: 768px) and (orientation: portrait)',
                  image: imageMobile,
                },
              ])
              : item?.image?.localFile
                ? imageLocal
                : false
          return (
            <SwiperSlide key={'swipe' + i}>
              {item?.image && images && (
                <div
                  className={`swipe-image ${item?.title || item?.subTitle || item?.text || item?.date
                    ? 'text-'
                    : ''
                    }${item.position == 'left' ? 'left' : 'right'}`}
                >
                  {num < 1 && !!images && (
                    <Parallax>
                      <GatsbyImage
                        image={images}
                        className="no-padding-image"
                        loading="eager"
                        alt={item.image.altText}
                      />
                    </Parallax>
                  )}
                  {num > 0 && !!images && (
                    <GatsbyImage
                      image={images}
                      className="no-padding-image"
                      loading="eager"
                      alt={item.image.altText}
                    />
                  )}
                </div>
              )}
              <div className={`text-wrap text-${item.position}`}>
                <div className="container-fluid">
                  <div
                    className={`row justify-content-${item.position == 'left' ? 'start' : 'end'
                      }`}
                  >
                    <div className="col-8 text">
                      {item?.subTitle && <h3>{item.subTitle}</h3>}
                      {item?.title && <h2>{item.title}</h2>}
                      {item?.text && <p>{item.text}</p>}
                      {item?.date && <FormattedDate item={item} />}
                    </div>
                  </div>
                </div>
              </div>
              {item?.link?.url && item?.link?.url.startsWith('/') && (
                <Link to={item.link.url} className="stretched-link"></Link>
              )}
              {item?.link?.url && !item?.link?.url.startsWith('/') && (
                <a href={item.link.url} className="stretched-link"></a>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export default Component
